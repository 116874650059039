import React, { useMemo, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import Input from '../../../atoms/Input/Input';
import AdvancedSelect from '../../../atoms/AdvancedSelect/AdvancedSelect';
import { countries } from '../../../../constants/countries';
import Flag from '../../../atoms/Flag/Flag';
import { components } from 'react-select';
import styles from './AddressField.css';
import useTranslate from '../../../../hooks/useTranslate';
import { getConfidenceFieldStyle } from '../../../../helpers/form';
import Select from '../../../atoms/Select/Select';
// Custom advanced-select components to display country flag
const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <Flag className={styles.AddressField__optionFlag} countryCode={data.value} /> {data.label}
  </components.Option>
);

Option.propTypes = {
  data: PropTypes.object,
};

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <Flag className={styles.AddressField__optionFlag} countryCode={data.value} /> {data.label}
  </components.SingleValue>
);

SingleValue.propTypes = {
  data: PropTypes.object,
};

function AddressRender({
  input,
  label,
  placeholder,
  id,
  validation: { onlyCountry, defaultFrance },
  readonly,
  confidence,
  meta: { error, touched },
}) {
  const Component = useMemo(() => (onlyCountry ? AdvancedSelect : Input), [onlyCountry]);
  const placeholderMemo = useMemo(() => {
    const i18n = useTranslate();
    return placeholder || placeholder === ''
      ? placeholder
      : i18n('AdvancedSelectField.placeholder');
  }, [placeholder]);

  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  ////////// Google autocomplete adress api
  const [predictions, setPredictions] = useState([]);
  const [pretictedValue, setPredictedValue] = useState('');

  const [value, setValues] = useState('');

  const googleAutocomplete = async (text) =>
    new Promise((resolve, reject) => {
      try {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          { input: text },
          resolve,
        );
      } catch (e) {
        reject(e);
      }
    });

  const debounceTimeout = 400;

  useEffect(() => {
    if (!onlyCountry) {
      const handleDebounce = setTimeout(async () => {
        try {
          if (!input.value) {
            return;
          }

          const nextPredictions = await googleAutocomplete(input.value);
          let newKeyPredictionsArray = nextPredictions
            ? nextPredictions.map((item) => {
                return {
                  value: item.description,
                  label: item.description,
                };
              })
            : [];
          setPredictions(newKeyPredictionsArray);
        } catch (e) {
          console.error(e);
        }
      }, debounceTimeout);

      return () => {
        clearTimeout(handleDebounce);
      };
    }
  }, [input.value, debounceTimeout, pretictedValue]);

  const streetSuggestions = () =>
    pretictedValue !== input.value && (
      <Select
        options={predictions}
        onChange={onClickSuggestions}
        className={styles.selectStreet}
        size={3}
      />
    );

  const onClickSuggestions = (values) => {
    input['value'] = values;
    setValues(values);
    setPredictedValue(values);
    document.querySelector(`[name$=${input.name}]`).focus();
  };
  return (
    <>
      <Component
        {...input}
        error={touched && error}
        value={input.value}
        onFocus={() => {
          return null;
        }}
        label={label}
        id={id}
        block
        placeholder={(onlyCountry && placeholderMemo) || placeholder}
        readonly={readonly}
        {...(onlyCountry && {
          options: countries(),
          autoselect: false,
          components: { Option, SingleValue },
          defaultFrance,
        })}
        style={style}
        className={
          predictions.length > 0 &&
          pretictedValue !== input.value &&
          input.value !== '' &&
          styles.googleApiActive
        }
      />
      {touched && error && <FieldError error={error} />}
      {!readonly &&
        !onlyCountry &&
        predictions &&
        predictions.length > 0 &&
        pretictedValue !== input.value &&
        input.value !== '' &&
        streetSuggestions()}
    </>
  );
}

AddressRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    onlyCountry: PropTypes.bool,
    defaultFrance: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  confidence: PropTypes.number,
};

function AddressField({
  name,
  label,
  required,
  placeholder,
  validation,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      required={required}
      verticalAlign={true}
      answersChoices={answersChoices}
    >
      <FieldHOC
        name={name}
        placeholder={placeholder}
        component={AddressRender}
        id={id}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

AddressField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  fieldHoC: PropTypes.func,
  answersChoices: PropTypes.array,
};

AddressField.defaultProps = {
  fieldHoC: Field,
  validation: {},
  answersChoices: null,
};

export default AddressField;
