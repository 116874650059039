import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import SelectTag from '../SelectTag/SelectTag';
import cssvars from '../../../styles/variables';
import styles from './AdvancedSelect.css';
import useTranslate from '../../../hooks/useTranslate';
import { useFormStyle } from '../FormStyle';
function AdvancedSelect({
  options,
  placeholder,
  value,
  readonly,
  onChange,
  onInputChange,
  onBlur,
  multi,
  autoselect,
  defaultFrance,
  error,
  customStyles: overrideStyles,
  components,
  loading,
  disabled,
  style: inputStyle,
  queryMode,
  remoteMode,
  name,
}) {
  const i18n = useTranslate();
  const selectRef = useRef();
  const formStyle = useFormStyle() || {};

  // See : https://react-select.com/styles#styles
  const customStyles = {
    ...overrideStyles,
    control: (style, state) => ({
      ...style,
      boxSizing: 'border-box',
      minHeight: 28,
      borderRadius: 10,
      fontFamily: cssvars['font-body'],
      borderColor: error
        ? cssvars['color-error']
        : state.isFocused
        ? cssvars['color-focus']
        : cssvars['color-grey'],
      boxShadow: 'none',
      fontSize: 'var(--font-size-input)',
      '&:hover': {
        border: `1px solid ${state.isFocused ? cssvars['color-focus'] : cssvars['color-grey']}`,
      },
      ...inputStyle,
      ...(overrideStyles.control && overrideStyles.control(style, state)),
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (style, state) => ({
      ...style,
      padding: '0 6px',
      ...(overrideStyles.dropdownIndicator && overrideStyles.dropdownIndicator(style, state)),
    }),
    option: (style) => ({
      ...style,
      fontFamily: cssvars['font-body'],
      fontSize: formStyle['--font-size-input'] || 12,
    }),
    valueContainer: (style) => ({
      ...style,
      padding: '0 6px',
    }),
    clearIndicator: (style) => ({
      ...style,
      padding: '0',
    }),
  };

  const handleChange = useCallback(
    (el) => {
      if (!el && value) onChange('');
      const elValue = remoteMode ? el : el.value;
      if (!multi) onChange(elValue);
      if (multi && !value) {
        onChange([elValue]);
      }

      if (multi && value && !value.includes(elValue)) {
        onChange([...value, elValue]);
      }
    },
    [value, multi, onChange],
  );

  // For multi mode, retrieve option tuple from value
  const valuesOptions = useMemo(
    () =>
      remoteMode
        ? value || []
        : multi && value
        ? value.map((val) => options.find((opt) => opt.value === val) || { label: value, value })
        : [],
    [value, multi, options],
  );

  // This is to autoselect the first option if the autoselect props is true
  useEffect(() => {
    if (defaultFrance && !value) {
      handleChange(options[options.indexOf(options.find((el) => el.value === 'FR'))]);
    } else if (autoselect && !value) {
      handleChange(options[0]);
    }
  }, []);

  const handleRemove = useCallback(
    (removedValue) => {
      if (!value) {
        return;
      }

      onChange(value.filter((v) => (remoteMode ? v.value !== removedValue : v !== removedValue)));
    },
    [value],
  );

  /**
   * Map the string values passed in the component to the options props, and return the right options
   */
  const getValue = () => {
    if (!value) return null;
    const arrayValue = Array.isArray(value) ? value : [value];

    if (remoteMode) {
      return arrayValue;
    }
    return arrayValue.map(
      (value) => options.find((opt) => opt.value === value) || { value, label: value },
    );
  };

  useEffect(() => {
    if (remoteMode || !value) {
      return;
    }
    if (!multi) {
      onChange(options.find((opt) => opt.value === value) ? value : null);
    } else {
      onChange(value.filter((v) => options.find((opt) => opt.value === v)));
    }
  }, []);

  const props = {
    options,
    onChange: handleChange,
    onInputChange,
    value: !multi ? getValue() : null,
    placeholder,
    isDisabled: readonly || disabled,
    isLoading: loading,
    menuPortalTarget: document.body,
    closeOnScroll: true,
    noOptionsMessage: ({ inputValue }) =>
      queryMode && !inputValue?.length
        ? i18n('AdvancedSelect.typeToSearch')
        : i18n('AdvancedSelect.noOptions'),
    isClearable: true,
  };
  const filterOption = (options) => {
    return options;
  };

  const handleOnProxyFocus = useCallback(() => {
    selectRef.current?.focus();
  }, []);

  return (
    <>
      <Select
        ref={selectRef}
        styles={customStyles}
        // Event returned by react-select mess up with redux-form
        onBlur={() => onBlur()}
        options={options}
        filterOption={remoteMode ? () => filterOption(options) : undefined}
        components={components}
        {...props}
      />
      <div className={styles.AdvancedSelect__tags}>
        {multi &&
          valuesOptions.map((option, key) => (
            <SelectTag key={key} onRemove={() => handleRemove(option.value)}>
              {option.label}
            </SelectTag>
          ))}
      </div>

      {/* Hidden button so that we can focus the Select via DOM */}
      <button
        name={`${name}_select_proxy`}
        onFocus={handleOnProxyFocus}
        style={{
          position: 'absolute',
          top: -1000,
          left: -1000,
        }}
      />
    </>
  );
}

AdvancedSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  multi: PropTypes.bool,
  autoselect: PropTypes.bool,
  defaultFrance: PropTypes.bool,
  onBlur: PropTypes.func,
  readonly: PropTypes.bool,
  error: PropTypes.bool,
  customStyles: PropTypes.object,
  components: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
  style: PropTypes.object,
  queryMode: PropTypes.bool,
  remoteMode: PropTypes.bool,
};

AdvancedSelect.defaultProps = {
  onChange: () => null,
  onBlur: () => null,
  multi: false,
  autoselect: false,
  customStyles: {},
  placeholder: '',
  style: {},
};

export default AdvancedSelect;
