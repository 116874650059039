import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import ReactQuill from '../../../helpers/react-quill';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

import Button from './../../atoms/Button/Button';
import { classnames } from './../../../helpers/classnames';
import { bem } from '../../../helpers/styles';
import styles from './RichText.css';
import SVG from './../../atoms/SVG/SVG';

// MATCHER VIDEO : Need display video in RichText if compose initiat value
const videoMatcher = (domNode) => {
  return {
    ops: [
      {
        insert: {
          video: domNode.querySelector('source').getAttribute('src'),
        },
        attributes: {
          src: domNode.querySelector('source').getAttribute('src'),
        },
      },
    ],
  };
};

// MATCHER AUDIO : Need display video in RichText if compose initiat value
const audioMatcher = (domNode) => {
  return {
    ops: [
      {
        insert: {
          audio: domNode.firstChild?.getAttribute('url'),
        },
        attributes: {
          url: domNode.firstChild?.getAttribute('url'),
        },
      },
    ],
  };
};

// INSER VALUE : Insert value By Format in last selection
const InsertValue = (quill, value, format) => {
  const range = quill.getSelection(true);
  if (range) {
    const index = range.index + range.length;
    quill.insertEmbed(index, format, value, 'user');
  }
};

const CustomToolbar = ({ readonly, id, hasModulesCustom, hide = false }) => {
  return (
    <div id={id} style={{ display: hide ? 'none' : undefined }}>
      <select className="ql-size" defaultValue={''}>
        <option value="small" />
        <option />
        <option value="large" />
        <option value="huge" />
      </select>
      <Button disabled={readonly} className="ql-bold"></Button>
      <Button disabled={readonly} className="ql-italic"></Button>
      <Button disabled={readonly} className="ql-underline"></Button>
      <Button disabled={readonly} className="ql-align" value=""></Button>
      <Button disabled={readonly} className="ql-align" value="center"></Button>
      <Button disabled={readonly} className="ql-align" value="right"></Button>
      <Button disabled={readonly} className="ql-list" value="bullet"></Button>
      <Button disabled={readonly} className="ql-link"></Button>
      {hasModulesCustom && <Button disabled={readonly} className="ql-video"></Button>}
      {hasModulesCustom && (
        <Button disabled={readonly} className="ql-audio">
          <SVG className="ql-audio-svg" glyph={'audio_play'} />
        </Button>
      )}
    </div>
  );
};

CustomToolbar.propTypes = {
  readonly: PropTypes.bool,
  id: PropTypes.string,
  hasModulesCustom: PropTypes.bool,
};

const RichText = ({
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  readonly,
  hasModulesCustom,
  style,
  validation,
}) => {
  const id = useMemo(() => `toolbar_${uuid()}`, []);
  const editorRef = useRef();
  const content = value;
  const InitEditor = useCallback((quill) => {
    const { clipboard, theme } = quill;
    const toolbar = quill.getModule('toolbar');

    clipboard.addMatcher('div.ql-video', videoMatcher);
    clipboard.addMatcher('div.ql-audio', audioMatcher);
    toolbar.addHandler('audio', () => {
      theme.tooltip.edit('audio');
    });

    onChange(clipboard.convert(value));
  }, []);

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
  };

  const handleBlur = useCallback(() => onBlur(), [value, onBlur]);

  /* LISTENERS : Necessary to use custom handler Tooltip (Action container with Input)
   * addListeners when mount composant
   * removeListeners when unmount composant
   ** tooltip => Instance Action
   ** textbox => Input
   ** root => Quill container node
   */
  let valueInput;

  const handleChange = (e) => {
    valueInput = e.target.value;
    e.stopPropagation();
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData;
    valueInput = clipboardData.getData('text');
    e.stopPropagation();
  };

  const handleClick = (e) => {
    const { current } = editorRef;
    const editor = current.getEditor();
    const {
      theme: {
        tooltip: { root },
      },
    } = editor;

    if (root.getAttribute('data-mode') === 'audio') {
      InsertValue(editor, valueInput, 'audio');
    }

    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    const { current } = editorRef;
    const editor = current.getEditor();
    const {
      theme: {
        tooltip: { root },
      },
    } = editor;

    if (root.getAttribute('data-mode') === 'audio' && e.key === 'Enter') {
      InsertValue(editor, valueInput, 'audio');
    }
    e.stopPropagation();
  };

  useEffect(() => {
    const { current } = editorRef;
    const editor = current.getEditor();
    const {
      theme: {
        tooltip: { textbox, root },
      },
    } = editor;

    if (hasModulesCustom) {
      textbox.addEventListener('change', handleChange);
      textbox.addEventListener('paste', handlePaste);
      root.querySelector('a.ql-action').addEventListener('click', handleClick);
      textbox.addEventListener('keydown', handleKeyDown);
      InitEditor(editor);
    }

    return () => {
      if (hasModulesCustom) {
        textbox.removeEventListener('change', handleChange);
        textbox.removeEventListener('paste', handlePaste);
        root.querySelector('a.ql-action').removeEventListener('change', handleClick);
        textbox.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [hasModulesCustom]);

  const hideRich = validation?.hideRich || false;

  return (
    <div className={classnames(['text-editor', bem(styles, 'RichText', { error })])} style={style}>
      <CustomToolbar
        readonly={readonly}
        id={id}
        hasModulesCustom={hasModulesCustom}
        hide={hideRich}
      />
      <ReactQuill
        className="editor"
        readOnly={readonly}
        value={content}
        placeholder={placeholder}
        ref={editorRef}
        onChange={onChange}
        onBlur={handleBlur}
        modules={modules}
      />
    </div>
  );
};

RichText.defaultValue = {
  hasModulesCustom: false,
};

RichText.propTypes = {
  readonly: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hasModulesCustom: PropTypes.bool,
  style: PropTypes.object,
};

export default RichText;
