import { isInside } from './formTree';
import { getTypologyInitialValues } from '../helpers/typology';


export const copyItem = (formId, canCopy, AllBlocks, AllFields, copyItem, blockFocus,fieldFocus, position,xDistance, pageRef, addField, addBlock) => {
    if (!canCopy) return null;
    const insideField =
    blockFocus &&
      AllFields.filter((field) => isInside(copyItem.box, field.box)).map((field) => field);
    const insideBlock =
    blockFocus &&
      AllBlocks.filter((block) => isInside(copyItem.box, block.box)).map((block) => block);

    if (fieldFocus) {
      const box = {
        x: Math.trunc(position.x / xDistance),
        y:
          Math.trunc(position.y / 70) +
          Math.trunc(pageRef.current && pageRef.current.base.scrollTop / 70),
        width: fieldFocus.box.width,
        height: fieldFocus.box.height,
      };
      addField(formId, {
        type: fieldFocus.type,
        fieldType: fieldFocus.type,
        box: box,
        data: getTypologyInitialValues(fieldFocus.type),
      });
    }
    if (blockFocus && insideBlock.length <= 1) {
      const box = {
        x: Math.trunc(position.x / xDistance),
        y:
          Math.trunc(position.y / 70) +
          Math.trunc(pageRef.current && pageRef.current.base.scrollTop / 70),
        width: blockFocus.box.width,
        height: blockFocus.box.height,
      };
      addBlock(formId, {
        color: blockFocus.color,
        name: blockFocus.name,
        box,
      });
    }
    if (blockFocus && insideField.length > 0) {
      insideField.map((item) => {
        addField(formId, {
          type: item.type,
          fieldType: item.type,
          box: {
            x: item.box.x - copyItem.box.x + Math.trunc(position.x / xDistance),
            y:
              item.box.y -
              copyItem.box.y +
              Math.trunc(position.y / 70) +
              Math.trunc(pageRef.current && pageRef.current.base.scrollTop / 70),
            width: item.box.width,
            height: item.box.height,
          },
          data: getTypologyInitialValues(item.type),
        });
      });
    }
    if (blockFocus && insideBlock.length > 1) {
      insideBlock.map((item) => {
        addBlock(formId, {
          color: item.color,
          name: item.name,
          box: {
            x: item.box.x - copyItem.box.x + Math.trunc(position.x / xDistance),
            y:
              item.box.y -
              copyItem.box.y +
              Math.trunc(position.y / 70) +
              Math.trunc(pageRef.current && pageRef.current.base.scrollTop / 70),
            width: item.box.width,
            height: item.box.height,
          },
        });
      });
    }
  };

export const updateCopy = (formId, blockfocus,fieldFocus,itemCopy, AllBlocks, AllFields, updateBlock ,updateField, setFocusedBlock, setFocusedField) => {
    const insideFieldPrev =
    blockfocus &&
      AllFields.filter((field) => isInside(itemCopy.box, field.box)).map((field) => field);
    const insideFieldNext =
    blockfocus &&
      AllFields.filter((field) => isInside(blockfocus.box, field.box)).map((field) => field);
    const insideBlockPrev =
    blockfocus &&
      AllBlocks.filter((block) => isInside(itemCopy.box, block.box)).map((block) => block);
    const insideBlockNext =
    blockfocus &&
      insideBlockPrev &&
      AllBlocks
        .filter((block) =>
          isInside(AllBlocks[AllBlocks.length - insideBlockPrev.length].box, block.box),
        )
        .map((block) => block);

    if (fieldFocus) {
      var clone = Object.assign({}, itemCopy);
      delete clone.id;
      delete clone.box;
      updateField(formId, fieldFocus.id, clone);
    }
    if (blockfocus && insideBlockPrev.length <= 1) {
      var clone = Object.assign({}, itemCopy);
      delete clone.id;
      delete clone.box;
      updateBlock(formId, blockfocus.id, clone);
    }
    if (blockfocus && insideBlockPrev.length <= 1 && insideFieldPrev.length > 0) {
      const clonePrevItem = insideFieldPrev.map((item) => {
        delete item.id;
        delete item.box;
        return item;
      });
      insideFieldNext.map((item, index) => {
        updateField(formId, item.id, clonePrevItem[index]);
      });
      setFocusedField(null);
    }
    if (blockfocus && insideBlockPrev.length > 1) {
      let insideBlocksFieldsPrev = [];
      let insideBlocksFieldsNext = [];

      insideBlocksFieldsPrev = insideBlockPrev.map((item, index) => {
        return AllFields
          .filter((field, index) => isInside(item.box, field.box))
          .map((field) => field);
      });
      insideBlocksFieldsNext = insideBlockNext.map((items, index) => {
        return (
          index === 0 &&
          AllFields.filter((field) => isInside(items.box, field.box)).map((field) => field)
        );
      });

      insideBlocksFieldsNext[0].map((item, index) => {
        delete insideBlocksFieldsPrev[0][index].id;
        delete insideBlocksFieldsPrev[0][index].box;
        updateField(formId, item.id, insideBlocksFieldsPrev[0][index]);
      });
      var cloneBlockPrev = Object.assign(insideBlockPrev);
      var cloneBlockNext = Object.assign(insideBlockNext);
      cloneBlockPrev.map((item) => {
        delete item.id;
        delete item.box;
      });
      cloneBlockNext.map((item, index) => {
        updateBlock(formId, item.id, cloneBlockPrev[index]);
      });
      setFocusedBlock(AllBlocks[AllBlocks.length - insideBlockPrev.length].id);
      setFocusedField(null);
    }
  };

