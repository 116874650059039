import React, { useState, useEffect } from 'react';
import { reflow } from '../../../../helpers/formTree';
import ContributeFormNode from '../ContributeFormNode';

// Root form node
export default function ContributeFormNodeRoot({
  node,
  onHeightChange,
  readonly,
  answersValues,
  blocksValues,
  reviewTree,
}) {
  // Tracks children boxHeights
  const [boxesHeights, setBoxesHeights] = useState({});
  // Tracks boxes overrides
  const [boxesOverrides, setBoxesOverrides] = useState([]);

  const setBoxeHeight = (i, height) => setBoxesHeights({ ...boxesHeights, [i]: height });

  useEffect(() => {
    // When a child height change we recompute all boxes heights
    const boxes = node.children.map((node, i) => ({
      ...node.box,
      height: boxesHeights[i] ? boxesHeights[i] : node.box.height,
    }));
    // and apply reflow() so that no children collides
    const boxesReflow = reflow(boxes);

    // Find the max height so we can passe it to the parent
    const maxHeight = boxesReflow.reduce((max, cur) => Math.max(max, cur.y + cur.height), 0);
    onHeightChange(maxHeight);

    // Sets the boxes overrides so children are re-drawn
    setBoxesOverrides(boxesReflow);
  }, [boxesHeights]);

  return node.children.map((node, i) => (
    <ContributeFormNode
      key={i}
      node={node}
      boxOverride={boxesOverrides[i]}
      onHeightChange={(height) => setBoxeHeight(i, height)}
      readonly={readonly}
      answersValues={answersValues}
      blocksValues={blocksValues}
      reviewTree={reviewTree}
    />
  ));
}
