export const ERROR_REQUIRED = 'required';
export const ERROR_MIN_CHARS = 'not_enough_chars';
export const ERROR_MAX_CHARS = 'too_many_chars';
export const ERROR_BELOW_MIN = 'below_minimum';
export const ERROR_ABOVE_MAX = 'above_maximum';
export const ERROR_MIN_WORDS = 'not_enough_words';
export const ERROR_MAX_WORDS = 'too_many_words';
export const ERROR_INTEGER = 'not_integer';
export const ERROR_NUMBER = 'not_number';
export const ERROR_DOMAINS = 'denied_domains';
export const ERROR_EMAIL = 'bad_format_email';
export const ERROR_PHONE = 'bad_format_phone';
export const ERROR_URL = 'bad_format_url';
export const ERROR_TIME_BELOW_MIN = 'time_below_minimum';
export const ERROR_TIME_ABOVE_MAX = 'time_above_maximum';
export const ERROR_DATE_BELOW_MIN = 'date_below_minimum';
export const ERROR_DATE_ABOVE_MAX = 'date_above_maximum';
export const ERROR_ADDRESS = 'invalid_address';
export const ERROR_REGEX = 'regex_not_match';
export const ERROR_CAPITALIZED = 'not_capitalized';
export const ERROR_BELOW_MINROWS = 'below_min_rows';
export const ERROR_ABOVE_MAXROWS = 'above_max_rows';
export const ERROR_BELOW_MINCHOICES = 'below_min_choices';
export const ERROR_ABOVE_MAXCHOICES = 'above_max_choices';
export const ERROR_RIB = 'invalid_rib';
