import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Textarea.css';
import { bem } from '../../../helpers/styles';

function Textarea({ rows, onChange, onBlur, value, error, disabled, placeholder }) {
  const props = {
    onChange: useCallback((e) => onChange(e), [onChange]),
    rows,
    disabled,
  };
  return (
    <textarea
      className={bem(styles, 'Textarea', { error })}
      {...props}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
    />
  );
}

Textarea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.any,
  disabled: PropTypes.bool,
};

Textarea.defaultProps = {
  required: false,
  rows: 2,
  onChange: () => null,
};

export default Textarea;
