import { v4 as id } from 'uuid';
import {
  FORM_FIELD_ADD,
  FORM_GET_SUCCESS,
  FORM_FIELD_UPDATE,
  FORM_GET,
  FORM_UPDATE_SUCCESS,
  FORM_UPDATE,
  FORM_BLOCK_ADD,
  FORM_BLOCK_UPDATE,
  FORM_BLOCK_DELETE,
  FORM_FIELD_DELETE,
  FORM_VALIDATIONRULE_ADD,
  FORM_VALIDATIONRULE_UPDATE,
  FORM_VALIDATIONRULE_DELETE,
  FORM_BLOCK_MOVE,
  FORM_ITERATIONOVERLOAD_ADD,
  FORM_ITERATIONOVERLOAD_UPDATE,
  FORM_ITERATIONOVERLOAD_DELETE,
} from '../actions/form';
import {
  EDITFORM_FOCUS_FIELD,
  EDITFORM_UPDATE,
  EDITFORM_TOGGLE_INFORMATION,
  EDITFORM_FOCUS_BLOCK,
  EDITFORM_FOCUS_VALIDATIONRULE,
  EDITFORM_FOCUS_COMPARERULE,
  SELECTMODE_ADD_FIELDS,
  SELECTMODE_DELETE_FIELDS,
  SELECTMODE_SET,
  SELECTMODE_SET_FIELDS,
  EDITFORM_FOCUS_ITERATIONRULE,
} from '../actions/editForm';
import { getSmallestBlock, reflowAfterAddHeader } from '../../helpers/formTree';
import { TASK_FORM_GET_SUCCESS } from '../actions/task';
import { CERTIFICATION_FORM_GET_SUCCESS } from '../actions/certification';
import { FIELD_REFERENCE } from '../../constants/formula';
import { updateFormBlockscopesForField, updateFormFieldTokens } from '../../helpers/formula';
import { emptyForm } from '../../constants/emtyForm';
import undoable from 'redux-undo';

export const initialState = {
  form: window && window.location.href.search("demo") > 0 ? emptyForm : null,
  focusedField: null,
  focusedBlock: null,
  focusedRule: null,
  focusedIterationRule: null,
  loading: false,
  dirty: false,
  informationOpen: false,
  selectMode: false,
  selectFields: [],
};

// Sets the "has header" boolean flag to true if needed
function setBlockHasHeader(block) {
  return {
    ...block,
    box: {
      ...block.box,
      hasHeader: block.nameEnabled || block.duplicable || block.foldable,
    },
  };
}

const editFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_GET:
    case FORM_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case FORM_GET_SUCCESS:
    case TASK_FORM_GET_SUCCESS:
    case CERTIFICATION_FORM_GET_SUCCESS:
    case FORM_UPDATE_SUCCESS:
      return {
        ...state,
        form: action.payload,
        loading: false,
        dirty: action.dirty || false,
      };
    case FORM_FIELD_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          fields: (state.form.fields || []).concat([action.payload.field]),
        },
        focusedField: action.payload.field.id,
        dirty: true,
      };
    case FORM_FIELD_UPDATE: {
      const hasMoved =
        Number.isInteger(action.payload?.data?.box?.y) ||
        Number.isInteger(action.payload?.data?.box?.x);
      const updatedForm = {
        ...state.form,
        fields: (state.form?.fields || []).map((field) => {
          if (field.id !== action.payload.fieldId) return field;
          const { type } = action.payload.data;

          return {
            // Box is always
            box: field.box,
            // If type is given AND it does not match existing field type,
            // we do not spread previous metadata values
            // This effectively removes unecessary metadata
            ...(type === field.type || !type ? field : {}),
            ...action.payload.data,
          };
        }),
      };
      const field = updatedForm.fields.find((field) => field.id === action.payload.fieldId);
      return {
        ...state,
        form: hasMoved
          ? updateFormBlockscopesForField(
            updatedForm,
            field.id,
            getSmallestBlock(field, updatedForm.blocks)?.id || null,
          )
          : updatedForm,
        dirty: true,
      };
    }
    case FORM_FIELD_DELETE:
      return {
        ...state,
        form: {
          ...state.form,
          fields: (state.form?.fields || []).filter((field) => field.id !== action.payload.fieldId),
          iterationOverloads: (state.form?.iterationOverloads || []).map((iterationOverload) => ({
            ...iterationOverload,
            formula: (iterationOverload.formula || []).filter(
              (token) => token.type !== FIELD_REFERENCE || token.field !== action.payload.fieldId,
            ),
          })),
        },
        focusedBlock:
          state.focusedField !== action.payload.fieldId
            ? state.focusedField
            : action.payload.fieldId,
        dirty: true,
      };
    case FORM_BLOCK_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          blocks: (state.form.blocks || []).concat([
            {
              ...action.payload.block,
              order: state.form.lastBlockOrder,
            },
          ]),
          lastBlockOrder: state.form.lastBlockOrder + 1,
        },
        focusedBlock: action.payload.block.id,
        dirty: true,
      };
    case FORM_BLOCK_UPDATE:
    case FORM_BLOCK_MOVE: {
      const { blockId, data } = action.payload;
      const blockIndex = state.form.blocks.findIndex((b) => b.id === blockId);

      if (blockIndex === -1) {
        // Block not found, early exit
        return state;
      }

      const block = state.form.blocks[blockIndex];
      const hadHeader = block.box.hasHeader;
      const newBlock = setBlockHasHeader({
        ...block,
        ...data,
      });
      let newForm = {
        ...state.form,
        blocks: (state.form.blocks || []).map((b) => (b.id === blockId ? newBlock : b)),
      };

      if (!hadHeader && newBlock.box.hasHeader) {
        // A header was added to the block, we need to reflow the form
        newForm = reflowAfterAddHeader(newBlock, newForm);
      }

      if (block.duplicable !== data?.duplicable) {
        newForm = updateFormFieldTokens(newForm, (fieldToken) => {
          const newToken = { ...fieldToken };
          newToken.blockScope = getSmallestBlock(
            newForm.fields.find((fieldNode) => fieldNode.id === fieldToken.field),
            newForm.blocks,
            { duplicable: true },
          )?.id;
          return newToken;
        });
      }

      return {
        ...state,
        form: newForm,
        dirty: true,
      };
    }
    case FORM_BLOCK_DELETE:
      return {
        ...state,
        form: {
          ...state.form,
          blocks: (state.form?.blocks || []).filter((block) => block.id !== action.payload.blockId),
        },
        focusedBlock:
          state.focusedBlock !== action.payload.blockId
            ? state.focusedBlock
            : action.payload.blockId,
        dirty: true,
      };
    case FORM_VALIDATIONRULE_ADD: {
      const rule = { id: id(), ...action.payload };
      return {
        ...state,
        focusedRule: rule.id,
        dirty: true,
        form: {
          ...state.form,
          validationRules: [...(state.form.validationRules || []), rule],
        },
      };
    }
    case FORM_VALIDATIONRULE_UPDATE: {
      const { ruleId, data } = action.payload;
      return {
        ...state,
        dirty: true,
        form: {
          ...state.form,
          validationRules: state.form.validationRules.map((rule) =>
            rule.id !== ruleId
              ? rule
              : {
                ...rule,
                ...data,
              },
          ),
        },
      };
    }
    case FORM_VALIDATIONRULE_DELETE: {
      const { ruleId } = action.payload;
      return {
        ...state,
        dirty: true,
        focusedRule: state.focusedRule === ruleId ? null : state.focusedRule,
        form: {
          ...state.form,
          validationRules: state.form.validationRules.filter((rule) => rule.id !== ruleId),
        },
      };
    }
    case FORM_ITERATIONOVERLOAD_ADD: {
      const rule = { id: id(), ...action.payload };
      return {
        ...state,
        focusedIterationRule: rule.id,
        dirty: true,
        form: {
          ...state.form,
          iterationOverloads: [...(state.form.iterationOverloads || []), rule],
        },
      };
    }
    case FORM_ITERATIONOVERLOAD_UPDATE: {
      const { ruleId, data } = action.payload;
      return {
        ...state,
        dirty: true,
        form: {
          ...state.form,
          iterationOverloads: state.form.iterationOverloads.map((rule) =>
            rule.id !== ruleId
              ? rule
              : {
                ...rule,
                ...data,
              },
          ),
        },
      };
    }
    case FORM_ITERATIONOVERLOAD_DELETE: {
      const { ruleId } = action.payload;
      return {
        ...state,
        dirty: true,
        focusedIterationRule:
          state.focusedIterationRule === ruleId ? null : state.focusedIterationRule,
        form: {
          ...state.form,
          iterationOverloads: state.form.iterationOverloads.filter((rule) => rule.id !== ruleId),
        },
      };
    }
    case EDITFORM_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
        dirty: true,
      };
    case EDITFORM_FOCUS_FIELD:
      return {
        ...state,
        focusedField: action.payload.field,
      };
    case EDITFORM_FOCUS_BLOCK:
      return {
        ...state,
        focusedBlock: action.payload.block,
      };
    case EDITFORM_FOCUS_VALIDATIONRULE:
      return {
        ...state,
        focusedRule: action.payload.ruleId,
      };
    case EDITFORM_FOCUS_COMPARERULE:
      return {
        ...state,
        focusedCompareRule: action.payload.ruleId,
      };
    case EDITFORM_FOCUS_ITERATIONRULE:
      return {
        ...state,
        focusedIterationRule: action.payload.ruleId,
      };
    case EDITFORM_TOGGLE_INFORMATION:
      return {
        ...state,
        informationOpen: !state.informationOpen,
      };
    case SELECTMODE_SET:
      return {
        ...state,
        selectMode: action.payload,
      };
    case SELECTMODE_ADD_FIELDS:
      return {
        ...state,
        selectFields: [...state.selectFields, action.payload],
        dirty: true,
      };
    case SELECTMODE_DELETE_FIELDS:
      return {
        ...state,
        selectFields: (state.selectFields || []).filter((field) => field.id !== action.payload.id),
        dirty: true,
      };
    case SELECTMODE_SET_FIELDS:
      return {
        ...state,
        selectFields: action.payload,
      };
    default:
      return state;
  }
};
const undoEdit = undoable(editFormReducer);
export default undoEdit;

