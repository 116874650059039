import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useFetchAction from '../../../hooks/useFetchAction';
import {
  getForm as getFormAction,
  addField as addFieldAction,
  updateField as updateFieldAction,
  deleteField as deleteFieldAction,
  addBlock as addBlockAction,
  moveBlock as moveBlockAction,
  updateBlock as updateBlockAction,
  deleteBlock as deleteBlockAction,
} from '../../../store/actions/form';
import Spinner from '../../atoms/Spinner/Spinner';
import styles from './WYSIWYGGridPage.css';
import DraggableGrid from '../../organisms/Grid/DraggableGrid';
import { useActions } from '../../../hooks/useActions';
import {
  currentFormSelector,
  fieldBoxesSelector,
  focusedFieldSelector,
  blocksBoxesSelector,
  allBoxesSelector,
  focusedBlockIdSelector,
  lowerBoxYSelector,
  selectModeSelector,
  fieldHasNoCompareRuleSelector,
  fieldHasCompareRuleSelector,
  fieldHasSortingRuleSelector,
  fieldHasHideIfRuleSelector,
  focusedBlockSelector,
} from '../../../store/selectors/editForm';
import {
  setFocusedField as setFocusedFieldAction,
  setFocusedBlock as setFocusedBlockAction,
  addSelectFields as addSelectFieldsAction,
} from '../../../store/actions/editForm';
import { getTypologyInitialValues } from '../../../helpers/typology';
import BlockBox from '../../organisms/WirkGrid/BlockBox/BlockBox';
import FieldBox from '../../organisms/WirkGrid/FieldBox/FieldBox';
import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_GRID_ROWHEIGHT,
  GRID_GUTTER,
  GRID_MIN_ROWS,
} from '../../../constants/grid';
import { PATH_BLOCKS_FORM, PATH_DATA_FORM, PATH_EDITFIELDS_FORM } from '../../../constants/paths';
import BoxPreview from '../../molecules/BoxPreview/BoxPreview';
import { useHistory, useRouteMatch } from 'react-router';
import { populatePath } from '../../../helpers/path';
import { FIELD, FIELD_STUB, BLOCK_STUB, BLOCK } from '../../../constants/draggableType';
import { COLOR_LIGHTYELLOW } from '../../../constants/colors';
import { getTypologyKind } from '../../../helpers/typology';
import { DATA } from '../../../constants/typology/data';
import SVG from '../../atoms/SVG/SVG';
import { bem } from '../../../helpers/styles';
import 'react-toastify/dist/ReactToastify.css';
import { selectFieldsSelector } from '../../../store/selectors/editForm';
import { fieldHasRuleSelector } from '../../../store/selectors/editForm';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import useTranslate from '../../../hooks/useTranslate';
import { isInside } from '../../../helpers/formTree';
import useQuery from '../../../hooks/useQuery';
import { isOverlap } from '../../organisms/Grid/GridHelpers';
import { updateCopy, copyItem } from '../../../helpers/copyPaste';
import { KEY_C, KEY_V, KEY_DELETE } from '../../../constants/keyboardFormControl';
import FormStyle from '../../atoms/FormStyle';

function WYSIWYGGridPage({ match }) {
  const i18n = useTranslate();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [hiddenBoxes, setHiddenBoxes] = useState([]);
  const formId = useMemo(() => match.params.formId, [match]);
  const form = useSelector(currentFormSelector);
  const focusedField = useSelector(focusedFieldSelector);
  const focusedBlockId = useSelector(focusedBlockIdSelector);
  const fieldsBoxes = useSelector(fieldBoxesSelector);
  const blocksBoxes = useSelector(blocksBoxesSelector);
  const allItems = useSelector(allBoxesSelector);
  const lowerY = useSelector(lowerBoxYSelector);
  const selectMode = useSelector(selectModeSelector);
  const selectFields = useSelector(selectFieldsSelector);
  const fieldHasRule = useSelector(fieldHasRuleSelector);
  const fieldHasNoCompareRule = useSelector(fieldHasNoCompareRuleSelector);
  const fieldHasCompareRule = useSelector(fieldHasCompareRuleSelector);
  const fieldHasSortingRule = useSelector(fieldHasSortingRuleSelector);
  const fieldHasHideIfRule = useSelector(fieldHasHideIfRuleSelector);
  const query = useQuery();

  const [updateBlock] = useActions([updateBlockAction]);

  const [getForm, loading, error] = useFetchAction(getFormAction);
  const [
    addField,
    updateField,
    setFocusedField,
    setFocusedBlock,
    deleteField,
    addBlock,
    moveBlock,
    addSelectFields,
  ] = useActions([
    addFieldAction,
    updateFieldAction,
    setFocusedFieldAction,
    setFocusedBlockAction,
    deleteFieldAction,
    addBlockAction,
    moveBlockAction,
    addSelectFieldsAction,
  ]);
  const focusedBlock = useSelector(focusedBlockSelector);
  const [deleteBlock] = useActions([deleteBlockAction]);

  const gridColumns = form?.gridSize || DEFAULT_GRID_COLUMNS;
  const gridRowHeight = (DEFAULT_GRID_COLUMNS * DEFAULT_GRID_ROWHEIGHT) / form?.gridSize;

  // Compute what is draggable based on current path
  const [fieldDraggable, blockDraggable] = useMemo(() => {
    switch (path) {
      case PATH_DATA_FORM:
      case PATH_EDITFIELDS_FORM:
        return [true, false];
      case PATH_BLOCKS_FORM:
        return [false, true];
      default:
        return [false, false];
    }
  }, [path]);

  /////////////////////////////////////// copy-past feature
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [itemCopiedData, setItemCopiedData] = useState({});
  const [copiedStatus, setCopiedStatus] = useState('');
  const dragRef = useRef();
  const [shadowBoxCopy, setShadowBoxCopy] = useState(false);
  let xDistance = (dragRef.current && dragRef.current.base.clientWidth / 12) - 5;

  const canCopy = useMemo(
    () =>
      (focusedBlock || focusedField) && focusedBlock
        ? !isOverlap(
            {
              height: focusedBlock.box.height,
              id: focusedBlock.id,
              type: focusedBlock.type,
              width: focusedBlock.box.width,
              x: Math.trunc(position.x / xDistance),
              y:
                Math.trunc(position.y / 70) +
                Math.trunc(dragRef.current && dragRef.current.base.scrollTop / 70),
            },
            allItems,
            { columns: gridColumns },
            focusedBlock
              ? blocksBoxes
                  .filter((block) => isInside(focusedBlock.box, block.box))
                  .map((block) => block)
                  .map((item) => {
                    return item.id;
                  })
              : [],
          )
        : focusedField
        ? !isOverlap(
            {
              height: focusedField.box.height,
              id: focusedField.id,
              type: focusedField.type,
              width: focusedField.box.width,
              x: Math.trunc(position.x / xDistance),
              y:
                Math.trunc(position.y / 70) +
                Math.trunc(dragRef.current && dragRef.current.base.scrollTop / 70),
            },
            allItems,
            { columns: gridColumns },
            [],
          )
        : null,
    [allItems, focusedBlock, focusedField, position, blocksBoxes],
  );

  useEffect(() => {
    const setPositionEvent = (e) => setPosition({ x: e.clientX - 380, y: e.clientY - 70 });
    const copyEvent = (e) => {
      if (position.x > 0) {
        if (e.ctrlKey && e.key === KEY_C.key) {
          setCopiedStatus('copy');
          setShadowBoxCopy(true);
        }
        if (e.ctrlKey && e.key === KEY_V.key) {
          (focusedField || focusedBlock) &&
            copyItem(
              formId,
              canCopy,
              blocksBoxes,
              fieldsBoxes,
              itemCopiedData,
              focusedBlock,
              focusedField,
              position,
              xDistance,
              dragRef,
              addField,
              addBlock,
            );
          setCopiedStatus('paste');
          setShadowBoxCopy(true);
        }
        if (e.key === KEY_DELETE.key) {
          focusedField && deleteField(formId, focusedField.id);
          focusedBlock && deleteBlock(formId, focusedBlock.id);
        }
      }
    };
    window.addEventListener('mousemove', setPositionEvent);
    document.addEventListener('keydown', copyEvent);

    return () => {
      window.removeEventListener('mousemove', setPositionEvent);
      document.removeEventListener('keydown', copyEvent);
    };
  }, [position, focusedField, focusedBlock, itemCopiedData, fieldsBoxes]);

  useEffect(() => {
    if (copiedStatus === 'copy') {
      setItemCopiedData(focusedField || focusedBlock);
    }
    if (copiedStatus === 'paste') {
      updateCopy(
        formId,
        focusedBlock,
        focusedField,
        itemCopiedData,
        blocksBoxes,
        fieldsBoxes,
        updateBlock,
        updateField,
        setFocusedBlock,
        setFocusedField,
      );
      setCopiedStatus('');
      setTimeout(function () {
        setShadowBoxCopy(false);
      }, 4000);
    }
  }, [copiedStatus]);
  ///////////////////////////////////////////////////////////////////// end of copy past feature

  // Fetch form on page load
  useEffect(() => {
    if (!form) {
      getForm(formId);
    }
  }, []);

  // Check deletion constraints
  const handleDelete = (form, field) => {
    if (form) {
      if (fieldHasRule(field.id)) {
        createToast(ERROR, i18n('toast.rule', { fieldName: field.label }));
        return;
      }
      const sorting = fieldHasSortingRule(field.id);
      if (sorting) {
        createToast(ERROR, i18n('toast.sorting', { sortingName: sorting.name }));
        return;
      }
      if (fieldHasNoCompareRule(field.id) || fieldHasCompareRule(field.id)) {
        createToast(ERROR, i18n('toast.compare'));
        return;
      }
      const hideIf = fieldHasHideIfRule(field.id);
      if (hideIf) {
        createToast(ERROR, i18n('toast.hideIf', { fieldName: hideIf.label || field.id }));
        return;
      }
      deleteField(form.id, field.id);
    }
  };
  // When a block is clicked, we redirect to appropriate page
  const handleBlockClick = useCallback(
    (blockId) => {
      setFocusedBlock(blockId);
      setFocusedField(null);
      // Redirect to blocks page if needed
      if (path !== PATH_BLOCKS_FORM) {
        history.push(populatePath(PATH_BLOCKS_FORM, { formId: form.id }, query));
      }
    },
    [history, form, path],
  );

  // When a field is clicked, we redirect to appropriate page
  const handleFieldClick = useCallback(
    (field) => {
      setFocusedField(field.id);
      setFocusedBlock(null);
      const neededPath =
        getTypologyKind(field.type) === DATA ? PATH_DATA_FORM : PATH_EDITFIELDS_FORM;
      // Redirect to fields or data page if needed
      if (neededPath !== path) {
        history.push(populatePath(neededPath, { formId: form.id }, query));
      }
    },
    [history, form, path],
  );

  const handleFieldClickSelectMode = useCallback(
    (field) => {
      if (selectMode) {
        const duplicate = selectFields ? selectFields?.find((el) => el.id === field.id) : null;
        if (!duplicate) {
          addSelectFields({
            label: field.type,
            type: field.type,
            id: field.id,
            box: field.box,
            multi: field.multi,
          });
        }
      }
    },
    [selectFields, selectMode],
  );

  // Add / update fields in state when they are droped
  const handleDropItem = useCallback(
    (item, position) => {
      const box = {
        x: typeof position.x !== 'undefined' ? position.x : item.x,
        y: typeof position.y !== 'undefined' ? position.y : item.y,
        width: item.width || 1,
        height: item.height || 1,
        ...position,
      };

      switch (item.type) {
        case FIELD_STUB:
          addField(formId, {
            type: item.fieldType,
            label: item.type,
            box,
            data: getTypologyInitialValues(item.fieldType),
          });
          setFocusedBlock(null);
          break;
        case FIELD:
          updateField(formId, item.id, {
            box,
          });
          setFocusedBlock(null);
          break;
        case BLOCK_STUB:
          addBlock(form.id, {
            color: COLOR_LIGHTYELLOW,
            name: `${i18n('BlockForm.block')} ${blocksBoxes.length + 1}`,
            box,
          });
          setFocusedField(null);
          break;
        case BLOCK:
          moveBlock(form.id, item.id, {
            box,
          });
          setFocusedField(null);
          break;
        default:
          break;
      }
    },
    [form],
  );

  const handleBlockMoveStart = useCallback(
    (item) => {
      const draggedBlock = blocksBoxes.find((block) => block.id === item.id);

      const childFieldsIds = fieldsBoxes
        .filter((field) => isInside(draggedBlock.box, field.box))
        .map((field) => field.id);

      const childBlocksIds = blocksBoxes
        .filter((block) => block.id !== draggedBlock.id && isInside(draggedBlock.box, block.box))
        .map((block) => block.id);

      setHiddenBoxes([...childFieldsIds, ...childBlocksIds]);
    },
    [blocksBoxes, fieldsBoxes],
  );

  const handleBlockMoveEnd = () => {
    setHiddenBoxes([]);
  };

  if (error) {
    return <p>Error</p>;
  }

  if (!form || loading) {
    return <Spinner />;
  }

  return (
    <FormStyle form={form}>
      {form.showFormTitle === true ? (
        <div className={styles.WYSIWYGGridPage__title}>{form.name}</div>
      ) : null}

      <DraggableGrid
        columns={gridColumns}
        rowHeight={gridRowHeight}
        gutter={GRID_GUTTER}
        accept={[FIELD, FIELD_STUB, BLOCK, BLOCK_STUB]}
        fixedRows={lowerY + GRID_MIN_ROWS}
        items={allItems}
        onDropItem={handleDropItem}
        renderPreview={BoxPreview}
        className={styles.WYSIWYGGridPage}
        dotBackground
        ref={dragRef}
      >
        <div
          className={bem(styles, 'WYSIWYGGridPage__emptyState', { visible: allItems.length === 0 })}
        >
          <SVG glyph="home_empty" className={styles.WYSIWYGGridPage__emptySVG} />
        </div>
        {shadowBoxCopy && (
          <div
            className={styles.copyBoxvisualisation}
            style={{
              position: 'absolute',
              top:
                dragRef.current && dragRef.current.base.scrollTop > 0
                  ? position.y + dragRef.current.base.scrollTop - 20
                  : position.y - 20,
              left: position.x - 20,
              zIndex: 80000,
              height:
                (focusedBlock && focusedBlock.box.height * 70) ||
                (focusedField && focusedField.box.height * 70),
              width:
                (focusedBlock && focusedBlock.box.width * xDistance) ||
                (focusedField && focusedField.box.width * xDistance),
              background: canCopy ? 'rgba(240,240,240,0.5)' : 'rgba(221,93,93,0.5)',
              borderRadius: '8px',
            }}
            onClick={() => setShadowBoxCopy(false)}
          ></div>
        )}
        {blocksBoxes.map((blockBox, i) =>
          !hiddenBoxes.includes(blockBox.id) ? (
            <BlockBox
              blockBox={blockBox}
              key={i}
              draggable={blockDraggable}
              focused={focusedBlockId === blockBox.id}
              onClick={() => handleBlockClick(blockBox.id)}
              onMoveStart={handleBlockMoveStart}
              onMoveEnd={handleBlockMoveEnd}
            />
          ) : null,
        )}
        {fieldsBoxes.map((fieldBox, i) =>
          !hiddenBoxes.includes(fieldBox.id) ? (
            <FieldBox
              fieldBox={fieldBox}
              key={i}
              draggable={fieldDraggable}
              focused={focusedField && focusedField.id === fieldBox.id}
              onDelete={() => handleDelete(form, fieldBox)}
              onClick={() =>
                selectMode ? handleFieldClickSelectMode(fieldBox) : handleFieldClick(fieldBox)
              }
            />
          ) : null,
        )}
      </DraggableGrid>
    </FormStyle>
  );
}

WYSIWYGGridPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      formId: PropTypes.string,
    }),
  }),
};

export default WYSIWYGGridPage;
