export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_DOMAIN_REGEX = /^([A-Za-z0-9_-]{2,})+\.[A-Za-z]{2,}$/;
export const PHONE_REGEX = /^[+]([-0-9]{1,5})[" "]([-.0-9]{8,})$/;
export const URL_REGEX = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
// Match "HH:SS" format
export const TIME_REGEX = /\d{1,2}:\d{1,2}$/;
// Match "YYYY-MM-DD" format
export const DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
export const TEXT_REGEX = /^[0-9 ,_/%'"()&*A-Za-zÀ-ÖØ-öø-ÿ-]*$/;
export const PATH_REGEX = /^[^/]+\/[^/].*$|^\/[^/].*\.pdf$/gim;
export const SCHEME_REGEX = /^https?:\/\//;
export const SEPARATOR_REGEX = /(?:,| )+/;
export const NUMBER_REGEX = /[0-9,.-]$/;
export const NUMBER_STRICT_REGEX = /^-?[0-9.]*$/;
export const CAPITALIZED_REGEX = /^(\s*[A-Z][^\s]*\s*)+$/;
export const IBAN_REGEX = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
