import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './MCQ.css';
import { bem } from '../../../helpers/styles';
import Input from '../Input/Input';

const MCQ = ({
  name,
  value,
  exclusive,
  options,
  autoselect,
  onChange,
  layout,
  readonly,
  disabled,
  style,
}) => {
  const type = exclusive ? 'radio' : 'checkbox';
  const classNameInput = style ? styles[`MCQ--${type}`] : '';
  const valueArray = useMemo(() => {
    if (value && exclusive && value !== '' && !options.find((option) => option.value === value))
      return ['other'];
    if (value && !exclusive) {
      const other = value?.find((checked) => !options.find((option) => option.value === checked));

      if (other) {
        return value.map((checked) => {
          if (checked === other) return 'other';
          return checked;
        });
      }
    }
    return Array.isArray(value) ? value : [value];
  }, [value]);

  const handleChange = useCallback(
    (event) => {
      event.preventDefault();

      const optionIndex = parseInt(event.target.getAttribute('data-option'), 10);
      const option = options[optionIndex];
      if (exclusive) {
        if (
          option.value === 'other' &&
          event.target.nextElementSibling.nextElementSibling.value === ''
        ) {
          onChange('other');
          return;
        }
        onChange(
          event.target.type === 'radio' && option.value === 'other'
            ? event.target.nextElementSibling.nextElementSibling.value
            : option.value,
        );
        return;
      }

      // Not-exclusive, we need to return the full array of values
      const index = valueArray.indexOf(option.value);
      if (index > -1) {
        const copy = [...valueArray];
        if (valueArray.indexOf('other') > -1) {
          const otherValue = document.querySelector(`[data-option="${options.length - 1}"]`)
            .nextElementSibling?.nextElementSibling.value;
          copy[valueArray.indexOf('other')] = otherValue;
        }
        copy.splice(index, 1);
        onChange(copy.filter((v) => !!v));
      } else {
        let returnValue =
          event.target.type === 'checkbox' && option.value === 'other'
            ? event.target.nextElementSibling.nextElementSibling.value
            : option.value;
        if (
          option.value === 'other' &&
          event.target.nextElementSibling.nextElementSibling.value === ''
        )
          returnValue = 'other';
        onChange([...valueArray, returnValue].filter((v) => !!v));
      }
    },
    [valueArray, options, exclusive],
  );
  const handleChangeOther = (value) => {
    const newArr = [...valueArray];
    if (!exclusive && value !== '' && valueArray.includes('other'))
      newArr[valueArray.indexOf('other')] = value;
    onChange(exclusive ? value : newArr);
  };

  useEffect(() => {
    if (autoselect && !value) {
      if (exclusive) {
        onChange(options[0].value);
      } else {
        onChange([options[0].value]);
      }
    } else if (value) {
      if (exclusive) {
        onChange(options.find((opt) => opt.value === value) ? value : null);
      } else if (Array.isArray(value)) {
        onChange(value.filter((v) => options.find((opt) => opt.value === v)));
      }
    }
  }, []);

  return (
    <div className={bem(styles, 'MCQ', { [layout]: true })}>
      {options.map(({ value, label }, key) => (
        <div className={bem(styles, 'MCQ__option', { [layout]: true })} key={key}>
          <input
            id={`${name}_MCQ_${key}`}
            name={`${name}_MCQ_${key}`}
            key={`MCQ-${key}`}
            type={type}
            className={classNameInput}
            data-option={key}
            checked={valueArray.includes(value)}
            onChange={handleChange}
            readOnly={readonly}
            disabled={disabled}
          />
          <label htmlFor={`${name}_MCQ_${key}`}>{label}</label>
          {value === 'other' && (
            <Input onChange={handleChangeOther} className={styles['MCQ--other-input']} />
          )}
        </div>
      ))}
    </div>
  );
};

MCQ.propTypes = {
  style: PropTypes.bool,
  exclusive: PropTypes.bool,
  autoselect: PropTypes.bool,
  allowOther: PropTypes.bool,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool.isRequired]),
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
};

MCQ.defaultProps = {
  style: false,
  exclusive: false,
  autoselect: false,
  allowOther: false,
  layout: 'vertical',
  onChange: () => {},
};

export default MCQ;
