import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Input from '../Input/Input';
import { bem } from '../../../helpers/styles';
import INDICATIFS, { DEFAULT_INDICATIF } from '../../../constants/indicatif';
import styles from './Phone.css';
import AdvancedSelect from '../AdvancedSelect/AdvancedSelect';
import Flag from '../Flag/Flag';

const customStyles = {
  container: (style) => ({
    ...style,
    minWidth: 56,
  }),
  dropdownIndicator: (style) => ({
    ...style,
    display: 'none',
  }),
  control: () => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  }),
  menu: (style) => ({
    ...style,
    minWidth: 200,
  }),
};
const customStylesError = {
  container: (style) => ({
    ...style,
    minWidth: 56,
    borderColor: 'rgba(255, 91, 97, 1);',
    borderRight: '1px solid rgba(220, 223, 230, 1);',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    display: 'none',
  }),
  control: () => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
    '&:hover': {
      borderColor: 'rgba(255, 91, 97, 1);',
      borderRight: '1px solid rgba(220, 223, 230, 1);',
    },
  }),
  menu: (style) => ({
    ...style,
    minWidth: 200,
  }),
};

// Override display of react-select for show shortLabel
// eslint-disable-next-line no-unused-vars
const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    {data.value.length < 4 && <Flag className={styles.Phone__optionFlag} countryCode={data.code} />}
    {data.value}
  </components.SingleValue>
);

SingleValue.propTypes = {
  data: PropTypes.object,
};

const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <Flag className={styles.Phone__optionFlag} countryCode={data.code} /> {data.label}
  </components.Option>
);

Option.propTypes = {
  data: PropTypes.object,
};

function Phone({ onChange, onBlur, value, placeholder, error, readonly, style, ...restProps }) {
  const [indicatif = '', digits = ''] = value ? value.split(' ') : [DEFAULT_INDICATIF, ''];

  const handleChangeIndicatif = (val) => {
    onChange(`${val} ${digits}`);
  };

  const handleChangeNumber = (val) => {
    onChange(`${indicatif} ${val}`);
  };

  return (
    <div className={bem(styles, 'Phone', { error })} value={value} onBlur={onBlur} {...restProps}>
      <AdvancedSelect
        options={INDICATIFS}
        className={styles.Phone__indicatif}
        onChange={handleChangeIndicatif}
        value={indicatif}
        disabled={readonly}
        customStyles={error ? customStylesError : customStyles}
        components={{ SingleValue, Option }}
        error={error}
        style={style}
      ></AdvancedSelect>
      <Input
        className={styles.Phone__input}
        placeholder={placeholder}
        onChange={handleChangeNumber}
        value={digits}
        readonly={readonly}
        style={style}
      />
    </div>
  );
}

Phone.defaultProps = {
  required: false,
  value: '',
  onChange: () => null,
  onBlur: () => null,
};

Phone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  style: PropTypes.object,
};

export default Phone;
