import React, { useMemo, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

export const isValidIBANNumber = (input) => {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ''), //keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), //match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  //check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  //rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  //final check
  return mod97(digits);
};

function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

function RibFieldRender({
  input,
  label,
  placeholder,
  id,
  readonly,
  confidence,
  meta: { error, touched },
  validation: { rib },
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);
  const format = (value, format) => {
    if (!value) {
      return '';
    }
    if (format) {
      const cleanIban = value
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase();

      const parts = [];

      if (cleanIban.length > 0) {
        parts.push(cleanIban.substring(0, 5));
      }

      if (cleanIban.length > 5) {
        parts.push(cleanIban.substring(5, 10));
      }

      if (cleanIban.length > 10) {
        parts.push(cleanIban.substring(10, 21));
      }

      if (cleanIban.length > 21) {
        parts.push(cleanIban.substring(21, 23));
      }

      return parts.join(' ');
    } else {
      const cleanIban = value
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase();

      const parts = [];

      if (cleanIban.length > 0) {
        parts.push(cleanIban.substring(0, 4));
      }

      if (cleanIban.length > 4) {
        parts.push(cleanIban.substring(4, 8));
      }

      if (cleanIban.length > 8) {
        parts.push(cleanIban.substring(8, 12));
      }

      if (cleanIban.length > 12) {
        parts.push(cleanIban.substring(12, 16));
      }

      if (cleanIban.length > 16) {
        parts.push(cleanIban.substring(16, 20));
      }

      if (cleanIban.length > 20) {
        parts.push(cleanIban.substring(20, 24));
      }
      if (cleanIban.length > 24) {
        parts.push(cleanIban.substring(24, 28));
      }

      return parts.join(' ');
    }
  };
  const [ibanValue, setIbanValue] = useState('' || input.value);
  const resetFormat = (value) => {
    setIbanValue(format(value, rib));
  };

  return (
    <>
      <Input
        type="text"
        {...input}
        value={ibanValue}
        error={touched && error}
        onChange={(value) => {
          resetFormat(value);
        }}
        label={label}
        id={id}
        block
        readonly={readonly}
        placeholder={placeholder}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

RibFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  confidence: PropTypes.number,
};

function RibField({
  name,
  label,
  required,
  information,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      answersChoices={answersChoices}
      verticalAlign={true}
    >
      <FieldHOC name={name} component={RibFieldRender} id={id} {...props} />
    </FormField>
  );
}

RibField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  answersChoices: PropTypes.array,
};

RibField.defaultProps = {
  fieldHoC: Field,
};

export default RibField;
