import React, { useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './DatetimeField.css';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function DateOrTimeFieldRender({
  input,
  type,
  label,
  placeholder,
  id,
  confidence,
  meta: { error, touched },
  disabled,
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Input
        {...input}
        type={type}
        error={touched && error}
        label={label}
        id={id}
        block
        placeholder={placeholder}
        disabled={disabled}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

DateOrTimeFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  type: PropTypes.string,
  iconRight: PropTypes.string,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

function DateAndTimeFieldRender({
  input,
  placeholder,
  id,
  confidence,
  meta: { error, touched },
  disabled,
}) {
  // The value is stored in format <date>T<time> so we need to split it
  const [dateValue, timeValue] = useMemo(() => (input.value ? input.value.split('T') : ['', '']), [
    input.value,
  ]);

  // The two change handlers take care of sending back a properly formatted value
  const handleDateChange = useCallback(
    (value) => {
      input.onChange(`${value}T${timeValue}`);
    },
    [input.value, input.onChange],
  );

  const handleTimeChange = useCallback(
    (value) => {
      input.onChange(`${dateValue}T${value}`);
    },
    [input.value, input.onChange],
  );

  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <div className={styles.DatetimeField__dateAndTime}>
        <Input
          onFocus={input.onFocus}
          onBlur={() => input.onBlur()}
          value={dateValue}
          onChange={handleDateChange}
          type="date"
          error={touched && error}
          id={id}
          block
          placeholder={placeholder}
          disabled={disabled}
          style={style}
        />
        <Input
          onFocus={input.onFocus}
          onBlur={() => input.onBlur()}
          value={timeValue}
          onChange={handleTimeChange}
          type="time"
          error={touched && error}
          id={id}
          block
          placeholder={placeholder}
          disabled={disabled}
          style={style}
        />
      </div>

      {touched && error && <FieldError error={error} />}
    </>
  );
}

DateAndTimeFieldRender.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  iconRight: PropTypes.string,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

function DatetimeField({
  name,
  label,
  datetimeFormat,
  validation,
  iconRight,
  fieldHoC: FieldHoC,
  verticalAlign,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  const format = (validation && validation.datetimeFormat) || datetimeFormat;

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      verticalAlign={verticalAlign}
      answersChoices={answersChoices}
    >
      {format === 'date' && (
        <FieldHoC
          name={name}
          type="date"
          iconRight={iconRight}
          component={DateOrTimeFieldRender}
          id={id}
          {...props}
        />
      )}
      {format === 'time' && (
        <FieldHoC
          name={name}
          type="time"
          iconRight={iconRight}
          component={DateOrTimeFieldRender}
          id={id}
          {...props}
        />
      )}
      {format === 'datetime' && (
        <FieldHoC name={name} component={DateAndTimeFieldRender} id={id} {...props} />
      )}
    </FormField>
  );
}

DatetimeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  datetimeFormat: PropTypes.string,
  iconRight: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  verticalAlign: PropTypes.bool,
  answersChoices: PropTypes.array,
};

DatetimeField.defaultProps = { datetimeFormat: 'date', verticalAlign: false, fieldHoC: Field };

export default DatetimeField;
