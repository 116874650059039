import React, { useCallback } from 'react';
import Input from '../Input/Input';
import PropTypes from 'prop-types';
import styles from './Url.css';
import { bem } from '../../../helpers/styles';

function Url({ onChange, onBlur, value, id, name, whitelist, blacklist, error, style }) {
  const handleChange = useCallback(
    (el) => {
      onChange(el);
    },
    [value, onChange],
  );

  const props = {
    onChange: handleChange,
    value,
    whitelist,
    blacklist,
  };

  return (
    <div className={bem(styles, 'Url', { error })}>
      <Input {...props} onBlur={onBlur} id={id} name={name} style={style}></Input>
    </div>
  );
}

Url.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  whitelist: PropTypes.string,
  blacklist: PropTypes.string,
  error: PropTypes.any,
  style: PropTypes.object,
};
Url.defaultProps = {
  value: '',
};

export default Url;
