import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Url from '../../../atoms/Url/Url';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function UrlRender({ input, label, id, confidence, meta: { error, touched } }) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Url {...input} error={touched && error} label={label} id={id} block style={style} />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

UrlRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  confidence: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  validation: PropTypes.any,
};

function UrlField({
  name,
  label,
  required,
  information,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={true}
      answersChoices={answersChoices}
    >
      <FieldHOC name={name} component={UrlRender} id={id} {...props} />
    </FormField>
  );
}

UrlField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  answersChoices: PropTypes.array,
};
UrlField.defaultProps = {
  fieldHoC: Field,
};

export default UrlField;
