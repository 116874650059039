import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';
import SVG from '../SVG/SVG';
import styles from './Input.css';
import { float, numberRegex } from '../../../helpers/parse';

function Input({
  type,
  mode,
  placeholder,
  name,
  id,
  value,
  onChange,
  onBlur,
  onFocus,
  className,
  style,
  error,
  block,
  readonly,
  iconRight,
  labelRight,
  initialValue,
  inputRef,
  ...props
}) {
  const classname = classnames([
    bem(styles, 'Input', { error, block, icon: !!iconRight || !!labelRight }),
    className,
  ]);

  const handleChange = (value) => {
    let newVal = value;

    if (mode === 'number') newVal = numberRegex(value);
    if (type == 'number') newVal = float(value);

    return newVal;
  };

  const input = (
    <input
      ref={inputRef}
      type={type}
      placeholder={placeholder}
      name={name}
      id={id}
      value={value}
      defaultValue={initialValue ? initialValue : null}
      onChange={({ target: { value } }) => {
        const parseValue = handleChange(value);
        onChange(parseValue);
      }}
      onBlur={(event) => {
        if (mode === 'number') {
          onBlur();
        } else {
          onBlur(type === 'number' ? parseFloat(event.target.value) : event.target.value);
        }
      }}
      onKeyPress={(event) => {
        if (mode === 'number') {
          const { key } = event;
          const { value, selectionStart } = event.target;
          const nextValue = `${value.slice(0, selectionStart)}${key}${value.slice(selectionStart)}`;
          const parseValue = handleChange(nextValue);

          if (nextValue !== parseValue) {
            onChange(parseValue);
            event.preventDefault();
          }
        }
      }}
      onFocus={onFocus}
      className={classname}
      style={style}
      error={error}
      readOnly={readonly}
      {...props}
      size={1}
    />
  );

  return iconRight || labelRight ? (
    <div className={styles.Input__iconWrapper}>
      {input}
      {labelRight && <div className={styles.Input__icon}>{labelRight}</div>}
      {iconRight && <SVG className={styles.Input__icon} glyph={iconRight} />}
    </div>
  ) : (
    input
  );
}

Input.defaultProps = {
  type: 'text',
  onChange: () => null,
  onFocus: () => null,
  onBlur: () => null,
  initialValue: null,
};

Input.propTypes = {
  mode: PropTypes.oneOf(['text', 'number', 'time', 'date']),
  type: PropTypes.oneOf(['text', 'number', 'time', 'date']),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.string,
  error: PropTypes.bool,
  block: PropTypes.bool,
  readonly: PropTypes.bool,
  iconRight: PropTypes.string,
  labelRight: PropTypes.string,
  initialValue: PropTypes.string,
};

export default Input;
