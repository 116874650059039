import React from 'react';
import Paragraph from '../../molecules/Paragraph/Paragraph';
import Proptypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import { reduxForm } from 'redux-form';
import Form from '../../atoms/Form/Form';
import styles from './InformationForm.css';
import RichTextField from '../../molecules/Field/RichText/RichTextField';

const InformationForm = ({ handleSubmit, onBlur, disabled }) => {
  const i18n = useTranslate();

  return (
    <Form onSubmit={handleSubmit} className={styles.InformationForm}>
      <Paragraph
        title={i18n('DrawerContentInfo.title')}
        content={i18n('DrawerContentInfo.description')}
        icon="info"
      />
      <RichTextField name="instructions" readonly={disabled} onBlur={onBlur} hasModulesCustom />
    </Form>
  );
};

InformationForm.propTypes = {
  handleSubmit: Proptypes.func.isRequired,
  onBlur: Proptypes.func.isRequired,
  disabled: Proptypes.bool,
};

export default reduxForm({
  form: 'instructions',
})(InformationForm);
